import { MatchedOrderViewState } from "@/contractor/pages/home/invoices/pages/invoice-verification/enums/MatchedOrderViewState";
import { useInvoiceMatchedOrder } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceMatchedOrderProvider";
import { useInvoiceVerification } from "@/contractor/pages/home/invoices/pages/invoice-verification/providers/InvoiceVerificationProvider";
import { usePriceCalculation } from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { ExpandedReleaseItem } from "@/contractor/pages/home/release/providers/ReleaseProvider";
import { useReleaseUpdate } from "@/contractor/pages/home/release/providers/ReleaseUpdateProvider";
import { UpdateContractorReleaseInput } from "@/generated/graphql";
import { useCallback, useMemo } from "react";

export const useInvoiceReleaseItemUnitPriceInput = (
  item: ExpandedReleaseItem,
) => {
  const { updateRelease, release } = useReleaseUpdate();
  const { invoice, updateInvoice } = useInvoiceVerification();
  const { calcExtPrice } = usePriceCalculation();
  const { useInvoiceItemPrice, matchedOrderViewState } =
    useInvoiceMatchedOrder();

  const readonly = useMemo(
    () =>
      !(
        (item.invoiceItems || [])?.length > 0 &&
        useInvoiceItemPrice &&
        matchedOrderViewState === MatchedOrderViewState.EDIT_INVOICE_COVERAGES
      ),
    [item.invoiceItems, useInvoiceItemPrice, matchedOrderViewState],
  );

  const updatePrice = useCallback(
    async (price: string | null) => {
      if (item.id) {
        const invoicedItem = item.invoiceItems?.[0];
        if (useInvoiceItemPrice && !!invoicedItem) {
          await updateInvoice(
            {
              id: invoice?.id || "",
              updatedInvoicedReleaseItems: [
                {
                  id: invoicedItem.id,
                  releaseItemId: item.id,
                  unitPrice: price,
                  quantity: invoicedItem?.quantity || undefined,
                },
              ],
              releaseId: invoice?.release?.id || "",
            },
            { bulkUpdate: true },
          );
        }
        if (release) {
          const input: UpdateContractorReleaseInput = {
            releaseId: release?.id,
            version: release?.version,
            updates: [
              {
                releaseItemId: item.id,
                unitPrice: price,
              },
            ],
          };
          await updateRelease(input);
        }
      }
    },
    [
      release,
      item.id,
      item.invoiceItems,
      invoice?.id,
      invoice?.release?.id,
      useInvoiceItemPrice,
      updateRelease,
      updateInvoice,
    ],
  );

  const differentPrices = useMemo(() => {
    const hint = invoice?.releaseItemHints?.find(
      (hint) => hint.releaseItem.id === item.id,
    );
    const extPrice = calcExtPrice(item.quantityDecimal, item.unitPrice);
    const invoiceExtPrice = calcExtPrice(
      hint?.invoiceItem?.quantityDecimal,
      hint?.invoiceItem?.unitPrice,
    );

    return (
      hint &&
      hint?.invoiceItem?.unitPrice &&
      hint?.invoiceItem?.unitPrice != "0" &&
      item?.unitPrice &&
      hint?.invoiceItem?.unitPrice !== item?.unitPrice &&
      extPrice !== invoiceExtPrice
    );
  }, [
    calcExtPrice,
    invoice?.releaseItemHints,
    item.id,
    item.quantityDecimal,
    item.unitPrice,
  ]);

  const invoicedPriceDifferentThanOrdered = useMemo(
    () =>
      item.invoiceItems?.[0]?.unitPrice !== null &&
      item.invoiceItems?.[0]?.unitPrice !== undefined &&
      item.invoiceItems[0].unitPrice !== item.unitPrice,
    [item.invoiceItems, item.unitPrice],
  );

  return {
    differentPrices,
    invoicedPriceDifferentThanOrdered,
    readonly,
    updatePrice,
    useInvoiceItemPrice,
  };
};
