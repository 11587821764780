import { PrimaryButton } from "@/common/components/button/PrimaryButton";
import { FloatingFooter } from "@/common/components/footer/FloatingFooter";
import { ButtonsContainer } from "@/common/components/list-buttons-container/ButtonsContainer";
import { Loader } from "@/common/components/loader/Loader";
import { SpreadSheetTable } from "@/common/components/spreadsheet-table/SpreadSheetTable";
import { ColumnMapperProvider } from "@/common/providers/ColumnMapperProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import tw from "tailwind-styled-components";
import { useShallow } from "zustand/react/shallow";
import { useProjectStore } from "../../../../store/useProjectStore";
import { useProjectBudget } from "../../hooks/useProjectBudget";
import { useBudgetStore } from "../../store/useBudgetStore";
import { BudgetHeader } from "./budget-header/BudgetHeader";
import { useBudgetByCostCodesConfiguration } from "./BudgetByCostCodes.config";
import { BudgetHeaderForm, BudgetHeaderFormValues } from "./BudgetHeaderForm";
import { useBudgetByCostCodes } from "./useBudgetByCostCodes";

const Container = tw.div``;

const BudgetByCostCodesWithProviders = () => {
  const { spreadsheetConfig } = useBudgetByCostCodesConfiguration();
  const { budgetByCostCodes } = useBudgetStore(
    useShallow((state) => ({
      budgetByCostCodes: state.budgetByCostCodes,
    })),
  );
  const { budgetLink } = useProjectBudget();
  const { handleSubmit, getValues } = useFormContext<BudgetHeaderFormValues>();
  const [saving, setSaving] = useState(false);
  const { saveCostCodeBudget } = useBudgetByCostCodes();
  const onSave = useCallback(async () => {
    const input = getValues();
    setSaving(true);
    const result = await saveCostCodeBudget(input);
    setSaving(false);

    if (!result) {
      return;
    }
  }, [getValues, saveCostCodeBudget, setSaving]);

  const isReadOnly = useMemo(() => {
    return budgetLink?.autoSync;
  }, [budgetLink]);

  return (
    <Container>
      <BudgetHeader />
      <SpreadSheetTable
        items={budgetByCostCodes}
        columns={spreadsheetConfig}
        height="calc(100vh - 400px)"
        saving={saving}
        readOnly={isReadOnly}
        rowNumber={20}
      />
      <FloatingFooter>
        <ButtonsContainer>
          <PrimaryButton
            onClick={handleSubmit(onSave)}
            type="submit"
            loading={saving}
            testId="save-cost-codes"
          >
            <FormattedMessage id="SAVE_CHANGES" />
          </PrimaryButton>
        </ButtonsContainer>
      </FloatingFooter>
    </Container>
  );
};

const CostCodesWithColumnMapper = () => {
  const { spreadsheetConfig } = useBudgetByCostCodesConfiguration();
  return (
    <ColumnMapperProvider config={spreadsheetConfig}>
      <BudgetByCostCodesWithProviders />
    </ColumnMapperProvider>
  );
};

export const BudgetByCostCodes = () => {
  const { allowance, loading } = useProjectStore(
    useShallow((state) => ({
      allowance: state.allowance,
      loading: state.loading,
    })),
  );

  const { updateStoreData } = useBudgetStore(
    useShallow((state) => ({
      updateStoreData: state.updateStoreData,
    })),
  );

  useEffect(() => {
    updateStoreData(allowance);
  }, [allowance, updateStoreData]);

  if (loading) {
    return <Loader loading />;
  }

  return (
    <BudgetHeaderForm>
      <CostCodesWithColumnMapper />
    </BudgetHeaderForm>
  );
};
