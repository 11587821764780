import { useManufacturers } from "@/common/hooks/useManufacturers";
import {
  CategoryState,
  useToggleCategory,
} from "@/common/hooks/useToggleCategory";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import { toggleZoneCostCode } from "@/common/utils/zonesUtils";
import {
  AggregatedCostCodeFieldsFragment,
  AggregatedItemFieldsFragment,
  AggregatedZoneFieldsFragment,
  ProjectExtendedFieldsFragment,
  ProjectItemFieldsFragment,
} from "@/generated/graphql";
import { NoFunction, NoFunctionBoolean } from "@/types/NoFunction";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  UNSPECIFIED_COST_CODE_ID,
  useUnspecifiedCostCode,
} from "../../../../../common/hooks/useUnspecifiedCostCode";
import {
  UNSPECIFIED_ZONE_ID,
  useUnspecifiedZone,
} from "../../../../../common/hooks/useUnspecifiedZone";
import { useProjectMaps } from "../hooks/useProjectMaps";
import { useProjectZoneUtils } from "../hooks/useProjectZoneUtils";

type ProjectItemFieldsFragmentWithZone = ProjectItemFieldsFragment & {
  zoneId?: string;
};

type ZoneCategory = CategoryState<
  CategoryState<ProjectItemFieldsFragmentWithZone>
>;

type ProviderContextType = {
  zones: ZoneCategory[];
  groupedByZones: boolean;
  setGroupedByZones: (grouped: boolean) => void;
  selectedZones: string[] | null;
  setSelectedZones: (items: string[]) => void;
  costCodes: string[] | null;
  setCostCodes: (items: string[]) => void;
  filters: string[] | null;
  setFilters: (items: string[] | null) => void;
  tags: string[] | null;
  setTags: (items: string[]) => void;
  toggleZone: (name: string) => void;
  toggleCostCode: (costCodeId: string, zoneId: string) => void;
};

type Props = {
  project: ProjectExtendedFieldsFragment;
  children: React.ReactNode;
  defaultGroupedByZones?: boolean;
};

const ProviderContext = createContext<ProviderContextType>({
  zones: [],
  groupedByZones: false,
  setGroupedByZones: NoFunctionBoolean,
  selectedZones: [],
  setSelectedZones: NoFunction,
  costCodes: [],
  setCostCodes: NoFunction,
  filters: [],
  setFilters: NoFunction,
  tags: [],
  setTags: NoFunction,
  toggleZone: NoFunction,
  toggleCostCode: NoFunction,
});

export const ProjectItemsZonesProvider: FC<Props> = ({
  children,
  project,
  defaultGroupedByZones = true,
}) => {
  const [groupedByZones, setGroupedByZones] = useState<boolean>(
    defaultGroupedByZones ? project?.aggregatedZones.length > 1 : false,
  );
  const { unassignedZone } = useUnspecifiedZone();
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { manufacturers } = useManufacturers();
  const [zones, setZones] = useState<ZoneCategory[]>([]);
  const [selectedZones, setSelectedZones] = useState<string[]>([]);
  const [filters, setFilters] = useState<Array<string> | null>([]);
  const [selectedCostCodes, setSelectedCostCodes] = useState<Array<string>>([]);
  const [tags, setTags] = useState<Array<string>>([]);
  const { uoms } = useUomOptions();
  const { toggleCategory } = useToggleCategory(zones, setZones);
  const { groupedAggregatedZones } = useProjectZoneUtils(project);

  const { costCodeMap, tagsMap, projectItemsMap, estimatedItemsMap } =
    useProjectMaps(project);

  const filteredTags = useCallback(
    (item: AggregatedItemFieldsFragment) =>
      tags.length === 0 ||
      item.estimatedItems.some((ei) =>
        ei.tags.some((tag) => tags.includes(tag.id)),
      ),
    [tags],
  );

  const filterCostCodes = useCallback(
    (aggrCode: AggregatedCostCodeFieldsFragment) =>
      (selectedCostCodes.length === 0 ||
        selectedCostCodes.includes(
          aggrCode.costCode?.id ?? UNSPECIFIED_COST_CODE_ID,
        )) &&
      aggrCode.items.some(filteredTags),
    [selectedCostCodes, filteredTags],
  );

  const filterZones = useCallback(
    (aggr: AggregatedZoneFieldsFragment) =>
      (selectedZones.length === 0 ||
        selectedZones.includes(aggr.zone?.id ?? UNSPECIFIED_ZONE_ID)) &&
      aggr.costCodes.some(filterCostCodes),
    [selectedZones, filterCostCodes],
  );

  useEffect(() => {
    if (!project?.aggregatedZones) {
      return;
    }
    const flattenedZones = groupedByZones
      ? project?.aggregatedZones.filter(filterZones)
      : groupedAggregatedZones;

    const newZones = flattenedZones
      .map((aggr) => ({
        id: aggr.zone?.id || UNSPECIFIED_ZONE_ID,
        name: aggr.zone?.name || unassignedZone.name,
        isOpened: true,
        items: aggr.costCodes
          .filter(filterCostCodes)
          .map((aggrCode) => ({
            id: aggrCode.costCode?.id || UNSPECIFIED_COST_CODE_ID,
            parentId: aggr.zone?.id || UNSPECIFIED_ZONE_ID,
            name:
              costCodeMap.get(aggrCode.costCode?.id || UNSPECIFIED_COST_CODE_ID)
                ?.description || unassignedCostCode.description,
            isOpened: true,
            items: aggrCode.items.filter(filteredTags).map((item) => {
              const projectItem = projectItemsMap.get(item.projectItem.id);
              if (!projectItem) {
                return null;
              }

              const estimatedItems = item.estimatedItems.map((ei) => ({
                ...ei,
                tags: ei.tags.map((tag) => tagsMap.get(tag.id)),
                zone: groupedByZones
                  ? aggr.zone
                  : estimatedItemsMap.get(ei.id)?.zone,
                manufacturer: manufacturers.find(
                  (m) => m.id === ei.manufacturer?.id,
                ),
                uom: uoms.find((u) => u.id === ei.uom.id),
              }));

              const material = {
                ...projectItem.material,
                costCode: costCodeMap.get(
                  aggrCode.costCode?.id || UNSPECIFIED_COST_CODE_ID,
                ),
              };

              return {
                ...item,
                ...{
                  ...projectItem,
                  estimatedItems,
                },
                id: item.projectItem.id,
                zoneId: aggr.zone?.id || UNSPECIFIED_ZONE_ID,
                material,
                isOpened: false,
              };
            }),
          }))
          .sort((a, b) => a.name.localeCompare(b.name)),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
    setZones(newZones as ZoneCategory[]);
  }, [
    costCodeMap,
    estimatedItemsMap,
    filterCostCodes,
    filterZones,
    filteredTags,
    groupedAggregatedZones,
    groupedByZones,
    project?.aggregatedZones,
    projectItemsMap,
    tagsMap,
    unassignedCostCode.description,
    unassignedZone.name,
    manufacturers,
    uoms,
  ]);

  const toggleCostCode = useCallback(
    (costCodeId: string, zoneId: string) => {
      setZones(toggleZoneCostCode(costCodeId, zoneId, zones));
    },
    [zones],
  );

  const onChangeZones = useCallback((zones: string[]) => {
    setSelectedZones(zones);
  }, []);

  const onChangeCostCodes = useCallback((costCodes: string[]) => {
    setSelectedCostCodes(costCodes);
  }, []);

  const onChangeTags = useCallback((tags: string[]) => {
    setTags(tags);
  }, []);

  const onChangeGroupedByZones = useCallback((groupedByZones: boolean) => {
    setGroupedByZones(groupedByZones);
  }, []);

  return (
    <ProviderContext.Provider
      value={{
        zones,
        groupedByZones,
        setGroupedByZones: onChangeGroupedByZones,
        selectedZones,
        setSelectedZones: onChangeZones,
        costCodes: selectedCostCodes,
        setCostCodes: onChangeCostCodes,
        filters,
        setFilters,
        tags,
        setTags: onChangeTags,
        toggleZone: toggleCategory,
        toggleCostCode,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useProjectItemsZones = (): ProviderContextType =>
  useContext(ProviderContext);
