import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableHelpers } from "@/common/components/spreadsheet-table/hooks/useTableHelpers";
import { vendorLabelFormatter } from "@/common/components/vendor-picker/VendorPickerCustomRender";
import { useVendors } from "@/common/components/vendors/hooks/useVendors";
import { useUnspecifiedCostCode } from "@/common/hooks/useUnspecifiedCostCode";
import { useUomOptions } from "@/common/hooks/useUomOptions";
import {
  ExtraOption,
  SpreadSheetConfig,
} from "@/common/providers/ColumnMapperProvider";
import { isLumpSumUomText } from "@/common/utils/lumpSumItemUtils";
import { checkReleaseStatus } from "@/common/utils/status-checks/checkReleaseStatus";
import { useCostCodes } from "@/contractor/pages/admin/cost-structure/pages/cost-codes/hooks/useCostCodes";
import { useMaterials } from "@/contractor/pages/admin/org-items/pages/materials/hooks/useMaterials";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import { useContractorBuyout } from "@/contractor/pages/home/buyout/providers/ContractorBuyoutProvider";
import { useProjectStore } from "@/contractor/pages/home/project/store/useProjectStore";
import { ReleaseStatus } from "@/generated/graphql";
import { useCallback, useMemo } from "react";
import { useRelease } from "../../../providers/ReleaseProvider";

const getItemDetails = (detailText: string, colorClass: string) => {
  const itemContent = document.createElement("span");
  itemContent.className = "text-gray-600 font-light text-xs select-none mr-10";
  itemContent.innerHTML = detailText;
  const bubble = document.createElement("i");
  bubble.className = `absolute top-1/2 -translate-y-1/2 right-1 ${colorClass} fa-solid fa-circle`;
  itemContent.appendChild(bubble);
  return itemContent;
};

export const useReleaseItemsDecorator = () => {
  const { buyout } = useContractorBuyout();
  const { estimatedItems } = useProjectStore();
  const { vendors } = useVendors();
  const { formatCostCode } = useCostCodes();
  const { materials } = useMaterials();
  const { unassignedCostCode } = useUnspecifiedCostCode();
  const { getFormattedMaterialName } = useTableHelpers();
  const { hasPhaseCodes } = useOrgSettings();
  const { release } = useRelease();
  const { uoms } = useUomOptions();
  const { getVendorCode } = useVendors();
  const extraOptions = useMemo(() => {
    const lumpSumItems =
      release?.items
        .filter((i) => i.uom && isLumpSumUomText(i.uom.pluralDescription))
        .map((lumpSumItem) => {
          const material = materials.find(
            (m) => m.id === lumpSumItem.projectItem?.material.id,
          );

          return {
            name: lumpSumItem.name
              ? lumpSumItem.name
              : material
                ? getFormattedMaterialName(material)
                : "",
            value: lumpSumItem.id,
          } as ExtraOption;
        }) || [];
    const buyoutItems =
      buyout?.items
        .filter((b) =>
          materials.some(
            (i) => i.material.name === b.projectItem.material.material.name,
          ),
        )
        .map((buyoutItem) => {
          const material = materials.find(
            (m) =>
              m.material.name === buyoutItem.projectItem.material.material.name,
          );

          const buyoutItemContent = getItemDetails(
            `⟮${hasPhaseCodes ? buyoutItem?.tags[0]?.name : buyoutItem?.costCode?.description || unassignedCostCode.description}, ${buyoutItem?.quantityDecimal} ${material?.defaultEstimateUom.pluralDescription}⟯`,
            "text-green-800",
          );

          return {
            name: material ? getFormattedMaterialName(material) : "",
            content: buyoutItemContent,
            value: buyoutItem.id,
            prefilling: [
              [COLUMN_TYPE.BuyoutItemId, buyoutItem.id],
              [COLUMN_TYPE.Quantity, buyoutItem?.quantityDecimal],
              ...(hasPhaseCodes
                ? [[COLUMN_TYPE.PhaseCode, buyoutItem?.tags[0]?.name]]
                : [
                    [
                      COLUMN_TYPE.CostCode,
                      formatCostCode(buyoutItem?.costCode),
                    ],
                  ]),
              [COLUMN_TYPE.UOM, material?.defaultEstimateUom.pluralDescription],
              [COLUMN_TYPE.Manufacturer, buyoutItem?.manufacturer?.name],
              [COLUMN_TYPE.PrefilledPrice, buyoutItem?.unitPrice],
            ],
          } as ExtraOption;
        }) || [];

    const releaseIsSubmitted = checkReleaseStatus(release, [
      ReleaseStatus.Scheduled,
      ReleaseStatus.Requested,
      ReleaseStatus.PartiallyReceived,
      ReleaseStatus.Received,
    ]);

    const estimates =
      estimatedItems
        .filter(
          (estItem) =>
            !releaseIsSubmitted ||
            estItem.sellerOrgLocation?.id === release?.sellerOrgLocation?.id,
        )
        .map((estItem) => {
          const material = materials.find((m) => m.id === estItem.material.id);

          const estimatedItemDetails = getItemDetails(
            `⟮${estItem?.quantityDecimal} ${material?.defaultEstimateUom.pluralDescription}⟯`,
            "text-blue-500",
          );

          const vendor = vendors.find(
            (v) => v.sellerOrgLocation.id === estItem?.sellerOrgLocation?.id,
          );

          return {
            name: material ? getFormattedMaterialName(material) : "",
            content: estimatedItemDetails,
            value: estItem.id,
            prefilling: [
              [COLUMN_TYPE.EstimatedItemId, estItem.id],
              ...(vendor
                ? [
                    [
                      COLUMN_TYPE.Vendor,
                      vendorLabelFormatter(vendor?.sellerOrgLocation, [], {
                        vendorCode: getVendorCode(vendor),
                      }),
                    ],
                  ]
                : []),
              [COLUMN_TYPE.Quantity, estItem?.quantityDecimal],
              ...(hasPhaseCodes
                ? [[COLUMN_TYPE.PhaseCode, estItem?.tags[0]?.name]]
                : [[COLUMN_TYPE.CostCode, formatCostCode(estItem?.costCode)]]),
              [
                COLUMN_TYPE.UOM,
                uoms.find((u) => u.id === estItem?.uom.id)?.pluralDescription,
              ],
              [COLUMN_TYPE.Manufacturer, estItem?.manufacturer?.name],
              [COLUMN_TYPE.PrefilledPrice, estItem?.unitPrice],
              [COLUMN_TYPE.Zone, estItem?.zone?.name],
            ],
          } as ExtraOption;
        }) || [];
    return lumpSumItems.concat(buyoutItems).concat(estimates);
  }, [
    release,
    buyout?.items,
    materials,
    unassignedCostCode.description,
    getFormattedMaterialName,
    formatCostCode,
    hasPhaseCodes,
    estimatedItems,
    vendors,
    uoms,
    getVendorCode,
  ]);

  const getEstimatedItemId = useCallback(
    (id: string) => {
      const releaseItem = release?.items.find((item) => item.id === id);
      const estimatedItemIndex =
        releaseItem?.projectItem?.estimatedItems.findIndex((item) => {
          return item.zone?.id === releaseItem?.zone?.id;
        });
      return estimatedItemIndex !== -1
        ? `projectItem.estimatedItems.${estimatedItemIndex}.id`
        : "";
    },
    [release?.items],
  );

  const extraColumns = useMemo(() => {
    return [
      {
        header: "",
        columnId: "buyoutItem.id",
        columnType: COLUMN_TYPE.BuyoutItemId,
        metadataColumn: true,
      },
      {
        header: "",
        columnId: (id) => getEstimatedItemId(id),
        columnType: COLUMN_TYPE.EstimatedItemId,
        metadataColumn: true,
      },
    ] as SpreadSheetConfig[];
  }, [getEstimatedItemId]);

  return {
    extraOptions,
    extraColumns,
  };
};
