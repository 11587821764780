import { COLUMN_TYPE } from "@/common/components/spreadsheet-table/enums/columnType";
import { useTableValidators } from "@/common/components/spreadsheet-table/hooks/useTableValidators";
import { SpreadSheetConfig } from "@/common/providers/ColumnMapperProvider";

import { useUomOptions } from "@/common/hooks/useUomOptions";
import { isLumpSumItem } from "@/common/utils/lumpSumItemUtils";
import { useOrgSettings } from "@/contractor/pages/admin/org-settings/hooks/useOrgSettings";
import {
  ReleaseFieldsFragment,
  ReleaseStatus,
  TransactionKind,
} from "@/generated/graphql";
import { EmptyValidationFunction } from "@/types/NoFunction";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useReleaseActions } from "../../providers/ReleaseActionsProvider";
import { useReleaseItemsDecorator } from "./hooks/useReleaseItemsDecorator";

const SubmittedStatuses = [
  ReleaseStatus.Requested,
  ReleaseStatus.Scheduled,
  ReleaseStatus.Received,
  ReleaseStatus.PartiallyReceived,
];

export const useSpecifyDetailsConfiguration = (
  release?: ReleaseFieldsFragment | null,
) => {
  const { inputErrors } = useReleaseActions();
  const { uoms } = useUomOptions();
  const { extraOptions, extraColumns } = useReleaseItemsDecorator();
  const intl = useIntl();
  const { requiredValidator } = useTableValidators();
  const { settings } = useOrgSettings();

  const hasEndDate = useMemo(
    () =>
      release?.type.transactionKind === TransactionKind.Rental ||
      release?.type.transactionKind === TransactionKind.Services,
    [release],
  );

  const itemIsPartOfPoOrAssignedToInvoice = useCallback(
    (id: string, columnType: COLUMN_TYPE) => {
      const assignedToInvoiceItem = release?.items.find(
        (item) =>
          item.id === id && !!item.poItemLink && !!release?.poLink?.retroactive,
      );
      if (assignedToInvoiceItem) {
        switch (columnType) {
          case COLUMN_TYPE.Material:
            return assignedToInvoiceItem.projectItem?.material
              ? intl.$t({ id: "DISABLED_ITEM_ASSIGNED_TO_PO" })
              : "";
          case COLUMN_TYPE.UOM:
            return assignedToInvoiceItem.uom?.pluralDescription
              ? intl.$t({ id: "DISABLED_ITEM_ASSIGNED_TO_PO" })
              : "";
          default:
            return "";
        }
      }

      return "";
    },
    [intl, release?.items, release?.poLink?.retroactive],
  );

  const itemIsPartOfBuyout = useCallback(
    (id: string) => {
      return release?.items.find((item) => item.id === id && !!item.buyoutItem)
        ? intl.$t({ id: "DISABLED_RELEASE_ITEM_AS_IT_IS_PART_OF_BUYOUT" })
        : "";
    },
    [intl, release?.items],
  );

  const spreadsheetConfig = useMemo<SpreadSheetConfig[]>(
    () => [
      {
        header: intl.$t({ id: "ITEM_NAME" }),
        columnId: (id) =>
          isLumpSumItem(release?.items.find((itm) => itm.id === id))
            ? "name"
            : "projectItem.material",
        columnType: COLUMN_TYPE.Material,
        extraOptions,
        readOnlyFn: (id) =>
          itemIsPartOfPoOrAssignedToInvoice(id, COLUMN_TYPE.Material),
      },
      {
        header: intl.$t({ id: "VENDOR" }),
        columnId: "vendorName",
        columnType: COLUMN_TYPE.Vendor,
        hidden: SubmittedStatuses.includes(release?.status as ReleaseStatus),
        readOnlyFn: itemIsPartOfBuyout,
      },
      {
        header: intl.$t({ id: "MANUFACTURER" }),
        columnId: "manufacturer.name",
        columnType: COLUMN_TYPE.Manufacturer,
      },
      {
        header: intl.$t({ id: "TAG" }),
        columnId: "tags",
        columnType: COLUMN_TYPE.Tag,
      },
      {
        header: intl.$t({ id: "UOM" }),
        columnId: "uom.pluralDescription",
        options: uoms.filter((u) => u.mnemonic).map((o) => o.pluralDescription),
        columnType: COLUMN_TYPE.UOM,
        readOnlyFn: (id) =>
          itemIsPartOfPoOrAssignedToInvoice(id, COLUMN_TYPE.UOM),
      },
      {
        header: intl.$t({ id: "QUANTITY" }),
        columnId: "quantityDecimal",
        columnType: COLUMN_TYPE.Quantity,
        validator:
          inputErrors.length === 0 ? EmptyValidationFunction : undefined,
      },
      {
        header: intl.$t({ id: "PRICE" }),
        columnId: "unitPrice",
        columnType: COLUMN_TYPE.PrefilledPrice,
        validator: requiredValidator,
      },
      {
        header: intl.$t({ id: "COST_CODE" }),
        columnId: "costCode.code",
        columnType: COLUMN_TYPE.CostCode,
      },
      {
        header: intl.$t({ id: "PHASE_CODE" }),
        columnId: "tags",
        columnType: COLUMN_TYPE.PhaseCode,
      },
      {
        header: intl.$t({ id: "COST_TYPE" }),
        columnId: "costType.code",
        columnType: COLUMN_TYPE.CostType,
        hidden: !settings?.display?.itemCostTypes,
      },
      {
        header: intl.$t({ id: "ZONE" }),
        columnId: "zone.name",
        columnType: COLUMN_TYPE.Zone,
      },
      ...(hasEndDate
        ? [
            {
              header: intl.$t({ id: "END_DATE" }),
              columnId: "deliveryDate",
              columnType: COLUMN_TYPE.EndDate,
            },
          ]
        : [
            {
              header: intl.$t({ id: "DELIVERY_DATE" }),
              columnId: "deliveryDate",
              columnType: COLUMN_TYPE.DeliveryDate,
            },
          ]),
      {
        header: intl.$t({ id: "NOTES" }),
        columnId: "instructions.text",
        columnType: COLUMN_TYPE.Notes,
      },
      {
        header: intl.$t({ id: "TAX" }),
        columnId: "taxable",
        columnType: COLUMN_TYPE.Taxable,
      },
      ...extraColumns,
    ],
    [
      intl,
      extraOptions,
      release?.status,
      release?.items,
      itemIsPartOfBuyout,
      uoms,
      inputErrors.length,
      requiredValidator,
      settings?.display?.itemCostTypes,
      hasEndDate,
      itemIsPartOfPoOrAssignedToInvoice,
      extraColumns,
    ],
  );

  return spreadsheetConfig;
};
