import {
  ReleaseAdditionalChargesInputFormContext,
  useReleaseAdditionalChargesInput,
} from "@/common/components/release-additional-charges-and-taxes/hooks/useReleaseAdditionalChargesInput";
import { useTaxCalculation } from "@/common/components/sales-tax-input/hooks/useTaxCalculation";
import { DecimalSafe } from "@/common/utils/decimalSafe";
import { useFormPriceCalculation } from "@/contractor/pages/home/release/hooks/useFormPriceCalculation";
import {
  TotalProps,
  usePriceCalculation,
} from "@/contractor/pages/home/release/hooks/usePriceCalculation";
import { useReleaseUpdate } from "@/contractor/pages/home/release/providers/ReleaseUpdateProvider";
import { FC, useCallback, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { OutlinedButton } from "../../../../../../../../../common/components/button/OutlinedButton";
import { useExpandableFooterStore } from "../../../../../../../../../common/components/footer/stores/useExpandableFooterStore";
import { If } from "../../../../../../../../../common/components/if/If";
import { InvoiceFooterState } from "../../../../../../../../../common/components/invoices/invoice-details/types/InvoiceFooterState";
import { ExclamationCircleRed } from "../../../../../../../../../common/components/release-additional-charges-and-taxes/ReleaseAdditionalCharges.styles";
import { ReleaseAdditionalChargesAndTaxes } from "../../../../../../../../../common/components/release-additional-charges-and-taxes/ReleaseAdditionalChargesAndTaxes";
import { Tooltip } from "../../../../../../../../../common/components/tooltip/Tooltip";
import { vendorLabelFormatter } from "../../../../../../../../../common/components/vendor-picker/VendorPickerCustomRender";
import {
  AdditionalChargesFieldsFragment,
  UpdateContractorReleaseInput,
} from "../../../../../../../../../generated/graphql";
import { useRelease } from "../../../../../../release/providers/ReleaseProvider";
import { MatchedOrderViewState } from "../../../enums/MatchedOrderViewState";
import { useInvoiceImportExternalPO } from "../../../hooks/useInvoiceImportExternalPO";
import { useInvoiceTotals } from "../../../hooks/useInvoiceTotals";
import { useInvoiceMatchedOrder } from "../../../providers/InvoiceMatchedOrderProvider";
import { useInvoiceUpdateRelease } from "../../../providers/InvoiceUpdateReleaseProvider";
import { useInvoiceVerification } from "../../../providers/InvoiceVerificationProvider";
import { InvoiceCreateReleaseFormValues } from "../../matched-order/components/InvoiceVerificationForm";
import {
  Column,
  ContentColumn,
  PanelTitle,
  QuarterPanel,
  TitleRow,
} from "./InvoiceExpandable.styled";

const editableClasses = {
  item: "h-8 mt-1",
  subtotal: "mr-1",
  subtotalLabel: "-mr-1",
  tax: "h-9",
  total: "h-11 -mt-3",
  totalInner: "pt-1 pr-3",
  totalLabel: "mr-1",
};

const nonEditableClasses = {
  root: "flex-1 w-full pl-8 pt-1",
  item: "h-8",
  tax: "h-8",
  charges: "min-h-8",
  total: "h-11 -mt-2.5 mr-1",
  totalInner: "pt-1",
  totalLabel: "mr-2",
};

type Props = {
  form: UseFormReturn<InvoiceCreateReleaseFormValues>;
};

export const ReleasePanel: FC<Props> = ({ form }) => {
  const { release } = useRelease();
  const { watch, setValue } = form;
  const { invoice, setFooterState } = useInvoiceVerification();
  const { selectedRelease, matchedOrderViewState, setMatchedOrderViewState } =
    useInvoiceMatchedOrder();
  const { itemized } = useInvoiceUpdateRelease();
  const { importedPoItemized, importedPoIsImmutable } =
    useInvoiceImportExternalPO();
  const { invoiceSubtotal } = useInvoiceTotals({ release });
  const { setExpanded } = useExpandableFooterStore();
  const { updateRelease } = useReleaseUpdate();
  const { calcAdditionalChargesPrice } = usePriceCalculation();
  const { getTaxAmount } = useTaxCalculation();

  const customTaxAmount = watch("customTaxAmount");
  const chargesAmount = watch("additionalCharges");
  const { releaseInput } = useReleaseAdditionalChargesInput({
    form: form as unknown as UseFormReturn<ReleaseAdditionalChargesInputFormContext>,
  });
  const { total } = useFormPriceCalculation({
    form: form as unknown as UseFormReturn<TotalProps>,
  });

  const handleUpdateRelease = useCallback(
    (values: UpdateContractorReleaseInput) => {
      if (values.additionalCharges) {
        setValue("additionalCharges", values.additionalCharges);
      }
      if (
        values.customTaxAmount ||
        (values.clearCustomTaxAmount === false && customTaxAmount === undefined)
      ) {
        setValue(
          "customTaxAmount",
          values.customTaxAmount || release?.taxAmount || "0",
        );
        setValue("taxRate", undefined);
      }
      if (values.taxRate || values.clearCustomTaxAmount) {
        setValue("customTaxAmount", undefined);
        setValue("taxRate", values.taxRate || "0");
      }
      if (values.taxCodeId) {
        setValue("taxCodeId", values.taxCodeId);
      }
      if (values.taxType) {
        setValue("taxType", values.taxType);
      }
      if (values.paymentTerm || values.paymentTerm === 0) {
        setValue("paymentTerm", values.paymentTerm.toString());
      }
      if (values.taxVariance || values.clearTaxVariance) {
        setValue(
          "taxVariance",
          values.clearTaxVariance ? undefined : values.taxVariance,
        );
      }

      return true;
    },
    [customTaxAmount, release?.taxAmount, setValue],
  );

  const itemsCount = useMemo(
    () => ({
      partial: release?.items.filter((i) => i.unitPrice !== null).length || 0,
      total: release?.items.length || 0,
    }),
    [release?.items],
  );

  const lessInvoiceTotal = useMemo(() => {
    return new DecimalSafe(invoiceSubtotal || 0)
      .toDP(2)
      .greaterThan(new DecimalSafe(release?.netAmount || 0).toDP(2));
  }, [invoiceSubtotal, release?.netAmount]);

  const updateSalesTaxVariance = useCallback(
    async ({ taxVariance, clearTaxVariance }: UpdateContractorReleaseInput) => {
      if (release?.id) {
        return await updateRelease(
          {
            taxVariance,
            clearTaxVariance,
            releaseId: release?.id,
            version: release?.version,
          },
          { batch: false },
        );
      }
    },
    [release?.id, release?.version, updateRelease],
  );

  const displayOrderTitle = useMemo(
    () =>
      matchedOrderViewState &&
      (([
        MatchedOrderViewState.DEFAULT,
        MatchedOrderViewState.EDIT_INVOICE_COVERAGES,
        MatchedOrderViewState.FULFILLED,
      ].includes(matchedOrderViewState) &&
        invoice?.release) ||
        [
          MatchedOrderViewState.MATCH_ORDER,
          MatchedOrderViewState.IMPORT_ORDER,
          MatchedOrderViewState.CREATE_ORDER,
          MatchedOrderViewState.EDIT_ORDER,
        ].includes(matchedOrderViewState)),
    [matchedOrderViewState, invoice?.release],
  );

  return (
    <QuarterPanel className="bg-gray-200">
      <Column className="max-w-[45%] flex-1">
        <TitleRow>
          <If isTrue={displayOrderTitle}>
            <FormattedMessage id="ORDER" tagName={PanelTitle} />
          </If>
          <If isTrue={invoice?.release}>
            <If
              isTrue={
                matchedOrderViewState !== MatchedOrderViewState.EDIT_ORDER &&
                matchedOrderViewState !== MatchedOrderViewState.CREATE_ORDER &&
                matchedOrderViewState !== MatchedOrderViewState.MATCH_ORDER &&
                !release?.poLink?.immutable
              }
            >
              <OutlinedButton
                className="h-6 min-w-14"
                $small
                onClick={() => {
                  setMatchedOrderViewState(MatchedOrderViewState.EDIT_ORDER);
                  setFooterState(InvoiceFooterState.EDIT_ORDER);
                }}
              >
                <FormattedMessage id="EDIT" />
              </OutlinedButton>
            </If>
            <If
              isTrue={
                matchedOrderViewState !== MatchedOrderViewState.MATCH_ORDER &&
                matchedOrderViewState !==
                  MatchedOrderViewState.EDIT_INVOICE_COVERAGES &&
                matchedOrderViewState !== MatchedOrderViewState.EDIT_ORDER
              }
            >
              <OutlinedButton
                className="h-6 min-w-14"
                $small
                onClick={() => {
                  setMatchedOrderViewState(
                    MatchedOrderViewState.EDIT_INVOICE_COVERAGES,
                  );
                  setFooterState(InvoiceFooterState.EDIT_INVOICE_COVERAGES);
                  setExpanded(false);
                }}
              >
                <FormattedMessage id="ADJUST_INVOICE_COVERAGE" />
              </OutlinedButton>
            </If>
          </If>
        </TitleRow>
      </Column>
      <ContentColumn>
        <If
          isTrue={
            (matchedOrderViewState === MatchedOrderViewState.DEFAULT ||
              matchedOrderViewState ===
                MatchedOrderViewState.EDIT_INVOICE_COVERAGES ||
              matchedOrderViewState === MatchedOrderViewState.FULFILLED) &&
            invoice?.release
          }
        >
          <ReleaseAdditionalChargesAndTaxes
            release={release}
            total={release?.total}
            includeNotesPanel={false}
            includePaymentTerms={true}
            itemsCount={itemsCount}
            taxExempt={{
              isProjectTaxExempt: release?.project?.taxExempt,
              isVendorTaxExempt: release?.preferredVendor?.taxExempt,
              vendorName: vendorLabelFormatter(release?.sellerOrgLocation),
            }}
            totalTooltip={
              <If isTrue={lessInvoiceTotal}>
                <Tooltip
                  id="INVOICED_TOTAL_WARNING_TOOLTIP"
                  element={<ExclamationCircleRed />}
                >
                  <FormattedMessage id="ORDER_TOTAL_TOOLTIP" />
                </Tooltip>
              </If>
            }
            classes={nonEditableClasses}
            updateRelease={updateSalesTaxVariance}
            inlineEditTaxVariance
          />
        </If>

        <If
          isTrue={
            matchedOrderViewState === MatchedOrderViewState.CREATE_ORDER ||
            matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
          }
        >
          <If
            isTrue={
              importedPoItemized ||
              matchedOrderViewState === MatchedOrderViewState.IMPORT_ORDER
            }
          >
            <ReleaseAdditionalChargesAndTaxes
              total={total.toString()}
              releaseInput={releaseInput}
              customPaymentTerm={watch("paymentTerm")}
              editableByContractor={!importedPoIsImmutable}
              updateRelease={(values) =>
                handleUpdateRelease({ ...values, version: -1 })
              }
              includePaymentTerms
              editablePaymentTerms
              additionalCharges={
                watch("additionalCharges") as AdditionalChargesFieldsFragment[]
              }
              classes={
                matchedOrderViewState === MatchedOrderViewState.CREATE_ORDER
                  ? editableClasses
                  : nonEditableClasses
              }
            />
          </If>
          <If
            isTrue={
              !importedPoItemized &&
              matchedOrderViewState !== MatchedOrderViewState.IMPORT_ORDER
            }
          >
            <ReleaseAdditionalChargesAndTaxes
              readonly
              total={total.toString()}
              releaseInput={releaseInput}
              editableByContractor
              includeSubtotal
              includePaymentTerms
              customPaymentTerm={watch("paymentTerm")}
              forcePaymentTermsEditable
              includeAdditionalCharges
              updateRelease={(values) =>
                handleUpdateRelease({ ...values, version: -1 })
              }
              additionalCharges={
                watch("additionalCharges") as AdditionalChargesFieldsFragment[]
              }
              classes={nonEditableClasses}
            />
          </If>
        </If>
        <If isTrue={matchedOrderViewState === MatchedOrderViewState.EDIT_ORDER}>
          <ReleaseAdditionalChargesAndTaxes
            total={total.toString()}
            releaseInput={releaseInput}
            editableByContractor
            editableAdditionalCharges={itemized}
            editablePaymentTerms={itemized}
            includePaymentTerms={itemized}
            includeAdditionalCharges={itemized}
            release={itemized ? release : undefined}
            updateRelease={(values) =>
              handleUpdateRelease({
                ...values,
                releaseId: release?.id ?? "",
                version: release?.version ?? -1,
              })
            }
            additionalCharges={
              chargesAmount as AdditionalChargesFieldsFragment[]
            }
            includeSubtotal={itemized}
            classes={editableClasses}
          />
        </If>
        <If
          isTrue={matchedOrderViewState === MatchedOrderViewState.MATCH_ORDER}
        >
          <ReleaseAdditionalChargesAndTaxes
            total={selectedRelease?.total}
            releaseInput={{
              netAmount: selectedRelease?.netAmount,
              chargesAmount: calcAdditionalChargesPrice(
                selectedRelease?.additionalCharges,
              ),
              customTaxAmount: selectedRelease?.customTaxAmount,
              taxRate: selectedRelease?.taxRate,
              taxVariance: selectedRelease?.taxVariance,
              taxAmount: getTaxAmount(
                selectedRelease?.taxRate,
                selectedRelease?.customTaxAmount,
                selectedRelease?.netAmount,
              ),
              taxCodeId: selectedRelease?.taxCode?.id,
              taxType: selectedRelease?.taxType,
              orderTypeId: selectedRelease?.type?.id,
            }}
            editableByContractor={false}
            additionalCharges={selectedRelease?.additionalCharges}
            classes={editableClasses}
          />
        </If>
      </ContentColumn>
    </QuarterPanel>
  );
};
